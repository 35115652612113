const key = 'AIzaSyDGB5_ZQ0cWlA2WY0HDyhVuF-qIHPG_K-k'

export const firebaseConfig = {
  apiKey: "AIzaSyAwLnj4qr0iPmke0f1Xt_tImZxFX_4OZrQ",
  authDomain: "chat-2e96c.firebaseapp.com",
  projectId: "chat-2e96c",
  storageBucket: "chat-2e96c.appspot.com",
  messagingSenderId: "798593627416",
  appId: "1:798593627416:web:214aa296682e2392ea1a6d",
  measurementId: "G-YW5Z724QLL"
};

